<style>
.close {
  display: none;
}
.custom-file-input-new::-webkit-file-upload-button {
  visibility: hidden;
}

</style>
<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <div class="card">
            <div class="card-body table-responsive table-head">
              <div class="row bor-bot">
                <div class="col-md-8 pl-0">
                  <h1 class="m-0 text-dark pt-2 pb-3 text-left">Create Campaign</h1>
                </div>
                <div class="col-md-4 pt-1 pr-0">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right pr-0">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                  </div>
              </div>
              <div class="emiltempform createcampaign">
                <form ref="form" @submit.stop.prevent="emailSent"> 
                  <div class="row">
                    <div class="col-md-2 mt-3 text-right">
                      <label>Select Template Type</label>
                    </div>
                    <div class="col-md-3 mt-3">
                      <select v-model="form.template_type_id" class="form-control" @change="templateTypeChange">
                        <option disabled value="">Select</option>
                        <option v-for="(option,r) in templateType" :key="r" :value="option.id">
                          {{ option.type }}
                        </option>
                      </select>
                      <span class="text-sm text-danger" v-if="errors.template_type_id" style="display: block;">{{
                          errors.template_type_id[0]
                        }}</span>
                    </div>

                    <div class="col-md-7 mt-3">
                    </div>
                    <div class="col-md-2 mt-3 text-right">
                      <label>Select Template Name</label>
                    </div>
                    <div class="col-md-3 mt-3">
                      <select v-model="form.email_template_id" class="form-control"  @change="EmailTemplateChange">
                        <option disabled value="">Select</option>
                        <option v-for="(option,r) in emailTemplate" :key="r" :value="option.id">
                          {{ option.name }}
                        </option>
                      </select>
                      <span class="text-sm text-danger" v-if="errors.email_template_id" style="display: block;">{{
                          errors.email_template_id[0]
                        }}</span>
                    </div>

                    <div class="col-md-7 mt-3">
                      <button type="button" v-b-modal.modal-1 class="btn btn-outline-primary btnred colorwhite">{{ button.filter}}
                      </button>
                      <span class="text-sm text-danger" style="display: block;" v-if="errors.memberFilter">{{
                          errors.memberFilter
                        }}</span>
                    </div>
                    <div class="col-md-2 mt-3 text-right">
                      <label>From</label>
                    </div>
                    <div class="col-md-10 mt-3">
                      <input class="form-control" v-model="form.from"/>
                      <span class="text-sm text-danger" style="display: block;" v-if="errors.from">{{
                          errors.from[0]
                        }}</span>
                    </div>


                    <div class="col-md-2 mt-3 text-right">
                      <label>Subject</label>
                    </div>
                    <div class="col-md-10 mt-3">
                      <input class="form-control" v-model="form.subject"/>
                      <span class="text-sm text-danger" style="display: block;" v-if="errors.subject">{{
                          errors.subject[0]
                        }}</span>
                    </div>


                    <div class="col-md-2 mt-3 text-right">
                      <label>Message</label>
                    </div>
                    <div class="col-md-10 mt-3">
                      <ckeditor v-model="form.message"></ckeditor>
                      <!-- <vue-editor v-model="content"></vue-editor> -->
                      <span class="text-sm text-danger" style="display: block;" v-if="errors.message">{{
                          errors.message[0]
                        }}</span>
                    </div>
                    <div class="col-md-2 mt-3 text-right"><label>Attachments</label></div>
                    <div class="col-md-10 mt-3 atcmntbtn">

                      <input type="file" multiple :state="Boolean(form.attachment)" @change="onChangeAttachment()" ref="attachment" name="attachment"  accept=".jpg, .jpeg, .png, .pdf , .xls,.xlsx" width="100%" />
                      <div class="text-sm text-danger" style="display: block;">Note: <br>Support files : .jpg, .jpeg, .png, .pdf, .xls, .xlsx<br> Maximum file size: 2MB  </div>
                      <div v-if="attachments.length" > Attachments :
                        <div v-for="attachment in attachments" v-bind:key="attachment.value">
                        {{ attachment.name }}
                      </div>
                        <span class="text-sm text-danger" style="display: block;" v-if="errors.attachment">{{
                            errors.attachment
                          }}</span>
                        </div>
</div>

<!--                    <div class="col-md-2 mt-3 text-right">
                      <label>Schedule</label>
                    </div>
                    <div class="col-md-2 mt-3 datepicsec">
                      <div class="input-group birthdate">
                        <div class="birthdaypicker">
                          <date-pick
                              v-model="form.date"
                              :format="'MM/DD/YYYY'"
                              :displayFormat="'MM/DD/YYYY'"
                              v-mask="'##/##/####'"
                              :isDateDisabled="isFutureDate"
                              :parseDate="parseDatePick"
                          ></date-pick>
                        </div>
                        <span class="text-sm text-danger" style="display: block;"
                              v-if="errors.date">{{
                            errors.date
                          }}</span>
                      </div>
                      <input id="registration_start_date" type="hidden" class="form-control">
                    </div>

                    <div class="col-md-2 mt-3 timepicsec">
                      <label>
                        <b-form-timepicker placeholder="Time" v-model="form.time" locale="en"></b-form-timepicker>
                      </label>
                      <span class="text-sm text-danger" style="display: block;"
                            v-if="errors.time">{{
                          errors.time
                        }}</span>
                    </div>
                    <div class="col-md-6">
                    </div>-->
                    <div class="col-md-2 mt-3 text-right">
                      <label>Schedule</label>
                    </div>
                    <div class="col-md-10 mt-3 datepicsec">
                      <date-picker v-model="form.date" type="datetime" format="YYYY:MM:DD hh:mm a" :disabled-date="isFutureDate" :parseDate="parseDatePick"></date-picker>
                      <span class="text-sm text-danger" style="display: block;" v-if="errors.date">{{
                          errors.date[0]
                        }}</span>
                    </div>

                    <div class="col-md-12 text-right mt-3">
                      <button type="button" class="btn btn-secondary mr-3" @click="buttonCancel()">Cancel</button>
                      <button type="button" class="btn btn-primary mr-3 btnred" v-b-modal.msgpopup>Save</button>
                      <button :disabled="sentLoader" type="submit" class="btn btn-primary"><i class="fas" :class="[ sentLoader ? 'fa-circle-notch fa-spin' : '' ]"></i>Send</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="fade left">
            <b-modal id="modal-1" ref="filterPopup" :modal-class="myclass" no-close-on-backdrop="backdrop" title="Search by" class="fade left" hide-footer="true">
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <div class="searchbyform">
                <p  style="color: red;">{{error}}</p>
                  <div class="row">
                    <div class="col-md-4">
                      <label>Select Contact</label>
                    </div>
                    <div class="col-md-8">
                      <label class="radiobox">All Members
                        <input type="radio" v-model="searchForm.contact" value="All" @click="showHideFilter(true)">
                        <span class="check"></span>
                      </label>
                      <label class="radiobox">Create Custom
                        <input type="radio" v-model="searchForm.contact" value="Custom" @click="showHideFilter(false)">
                        <span class="check"></span>
                      </label>
                    </div>
                    <div class="col-md-4 mt-2">
                      <label>Membership Status</label>
                    </div>
                    <div class="col-md-8 mt-2">
                      <select v-model="searchForm.status"  ref="status_id" :disabled="disabled" class="form-control">
                        <option  value="">Select</option>
                        <template v-for="(id, val) in membershipStatus">
                          <option v-if="val > 0" :value="val" :key="val">{{ id }}</option>
                        </template>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label>Membership Type</label>
                    </div>
                    <div class="col-md-8 mt-3">
                      <select v-model="searchForm.MembershipId" ref="member_id" :disabled="disabled" class="form-control">
                        <option  value="">Select</option>
                        <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label>Registration Year</label>
                    </div>
                    <div class="col-md-8 mt-3">
                      <div class="form-group">
                        <select ref="year1" class="form-control" :disabled="disabled" v-model="searchForm.year">
                            <option selected="selected"  value="">Select</option>
                            <template v-for="year in years">
                                <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                         </template>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3" v-if="subdomain == 'waterski'">
                      <label>Region</label>
                    </div>
                    <div class="col-md-8 mt-3" v-if="subdomain == 'waterski'">
                      <select v-model="searchForm.RegionName" ref="region_id" :disabled="disabled" class="form-control">
                        <option  value="">Select</option>
                        <option v-for="(regionList, id) in regionsList" :value="regionList.id" :key="id">{{ regionList.name }}</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3" v-if="subdomain == 'shooting'">
                      <label>Group</label>
                    </div>
                    <div class="col-md-8 mt-3" v-if="subdomain == 'shooting'">
                      <select v-model="searchForm.group_id" ref="group_id" :disabled="disabled" class="form-control">
                        <option  value="">Select</option>
                        <option v-for="(group, id) in groups" :value="group.id" :key="id">{{ group.name }}</option>
                      </select>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label>State</label>
                    </div>
                    <div class="col-md-8 mt-3">
                      <Select2 v-model="searchForm.state_id"  :disabled="disabled"  ref="state_id" :options="states" :settings="{ multiple: true}" />
                    </div>
                    <div class="col-md-4 mt-3">
                      <label>By Email ID</label>
                    </div>
                    <div class="col-md-8 mt-3">
                      <input v-model="searchForm.byEmail" ref="email" class="form-control" type="email" :disabled="disabled" >
                    </div>
                    <div class="col-md-12 text-right mt-3">
                      <button type="submit" class="btn btn-secondary mr-3" @click="clearFilter()">Clear</button>
                      <button type="submit" @click="applyFilter()" class="btn btn-primary">Apply</button>
                    </div>
                  </div>
                
              </div>
            </b-modal>
          </div>

          <div>

            <b-modal id="msgpopup" ref="confirmPopup" :modal-class="notifypopup" centered hide-footer="true">
              <img src="/svg/waterski/email.svg"/>
              <h6 class="m-0 py-4 fnt-bold">Do you want to Save?</h6>
              <button type="button" class="btn smbtn btn-secondary mx-1" @click="hideModal()">Cancel</button>
              <button type="submit" class="btn smbtn btn-primary mx-1" @click="emailDraft()">Save</button>
            </b-modal>

            <b-modal ref="sentPopup" id="msgpopupsuccess" :modal-class="succsnotifypopup" centered hide-footer="true">
              <img src="/svg/waterski/successicon.svg"/>
              <h6 class="m-0 py-4 fnt-bold">Email Sent Successfully</h6>
            </b-modal>

            <b-modal ref="schedulePopup" id="msgpopupschedulesuccess" :modal-class="succsnotifypopup" centered hide-footer="true">
              <img src="/svg/waterski/successicon.svg"/>
              <h6 class="m-0 py-4 fnt-bold">Email Scheduled Successfully</h6>
            </b-modal>

            <b-modal ref="draftPopup" id="draftsavedpopup" :modal-class="succsnotifypopup" centered hide-footer="true">
              <img src="/svg/waterski/successicon.svg"/>
              <h6 class="m-0 py-4 fnt-bold">Draft Saved Successfully</h6>
            </b-modal>

          </div>

        </section>
      </div>
    </div>
  </section>
</template>
<script>
// import { VueEditor } from "vue2-editor";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CustomAlert from "../CustomAlert";
import $ from 'jquery';
import moment from "moment";
export default {

  computed: {
          years () {
              return new Date().getFullYear() +1;
          },
        },
  components:{
    CustomAlert,
    DatePicker
  }
  ,
  data() {
    return {
      content: "",
      dvalue: "",
      myclass: "left rightsidepopup closeiconopen",
      notifypopup: "text-center savepopup closeiconopen",
      succsnotifypopup: "text-center savepopup succspopup",
      templateType: [],
      // time2: null,
      form: {
        template_type_id: '',
        email_template_id: '',
        subject: '',
        message: '',
        content: '',
        created_by: localStorage.getItem("id"),
        status: 0,
        copied: 0,
        type: 1,
        start_date: '',
        attachment:[],
        date:''
      },
      disabled: true,
      membershipData: [],
      membershipStatus: [],
      seasons: [],
      errors: {
        datetime: null,
        attachment: null,
        memberFilter:null,
        date:null,
        time:null
      },
      emailTemplate: [],
      alertMessage: null,
      displayAlert: false,
      campaign_id: null,
      searchForm:{
        status:"",
        MembershipId:"",
        year:"",
        RegionName:"",
        state_id:"",
        byEmail:"",
        group_id:"",
        campaign_id:"",
        contact: null
      },
      emailTemplateDetails:[],
      backdrop:false,
      status:'',
      states:[],
      memberData:[],
      attachments:[],
      button:{
        filter:'Assign Members'
      },
      groupList:[],
      groups:"",
      error:"",
      sentLoader:false,
      filterSubmit:false
    };
  },
  methods: {
    showHideFilter(data) {
      if(data){
         this.clearFilter();
         this.error ='';
      }
      this.disabled = data;
    },
    getMembership() {
      axios.get(this.basePath + "api/getMemberships")
          .then((response) => {
            this.membershipData = response.data.data;
          })
          .catch(error => {
            console.log("error getting Membership info")
          })
    },
    getMembershipStatus() {
      axios.get(this.basePath + 'api/getMembershipStatus')
          .then(response => {
            this.membershipStatus = response.data.data;
          })
          .catch(err => {
            console.log(err);
          })
    },
    getSeason() {
      axios.get(this.basePath + 'api/get_season', {headers: this.adminHeaders})
          .then((res) => {
            this.seasons = res.data.data;
          })
          .catch((error) => {
            console.log('Error:' + error);
            return false;
          }).finally(() => {

          }
      );
    },
    applyFilter() {
      if(((this.searchForm.contact != 'All') && (this.searchForm.byEmail == undefined || this.searchForm.byEmail =='') && (this.searchForm.state_id == undefined || this.searchForm.state_id =='') && ((this.searchForm.RegionName  == undefined || this.searchForm.RegionName =='') || this.sassClientIds[this.subdomain] != 1) && (this.searchForm.year == undefined || this.searchForm.year =='') &&  (this.searchForm.MembershipId == undefined || this.searchForm.MembershipId =='') && (this.searchForm.status == undefined || this.searchForm.status=='') && ((this.searchForm.group_id == undefined || this.searchForm.group_id=='') || this.sassClientIds[this.subdomain] != 2))){
        this.error = 'Please select atleast one field';
        this.sentLoader = false;
        return false;
      }

        this.showLoader = true;
      setTimeout(() => {
        this.$refs['filterPopup'].hide();
        this.displayAlert=true;
        this.alertMessage ='Members Assigned Successfully';
        this.showLoader = false;
        this.filterSubmit=true;
        this.error =null;
        this.button.filter = 'View Members';

      }, 3000);
       /*console.log(this.searchForm.contact);
          if(this.subdomain == 'alliancefastpitch'){
            this.$delete(this.searchForm, 'RegionName');
            this.$delete(this.searchForm, 'group_id');
          }
          if(this.subdomain == 'shooting'){
            this.$delete(this.searchForm, 'RegionName');
          }
          if(this.subdomain == 'waterski'){
            this.$delete(this.searchForm, 'group_id');
          }
           if((this.searchForm.contact != 'All') && (this.searchForm.byEmail == undefined || this.searchForm.byEmail =='') && (this.searchForm.state_id == undefined || this.searchForm.state_id =='') && (this.searchForm.RegionName  == undefined || this.searchForm.RegionName =='') && (this.searchForm.year == undefined || this.searchForm.year =='') &&  (this.searchForm.MembershipId == undefined || this.searchForm.MembershipId =='') && (this.searchForm.status == undefined || this.searchForm.status=='') && ((this.searchForm.group_id == undefined || this.searchForm.group_id=='') && this.sassClientIds[this.subdomain] == 2 )){
                this.error = 'Please select atleast one field';
               return false;
            }
           else{
                this.error = '';
                this.showLoader = true;
                axios.post(this.basePath + "api/Campaign/filterMember", this.searchForm, {headers: this.adminHeaders})
                    .then((response) => {
                      this.memberData = response.data.data;
                      console.log(this.memberData.length);
                      if(this.memberData.length <= 0){
                        this.alertMessage ='Data not found';
                        this.displayAlert = true;
                      }
                      else if(this.memberData.length > 0){
                        this.alertMessage ='Members Assigned Successfully';
                        this.displayAlert = true;
                      }
                      this.showLoader = false;
                      this.button.filter = 'View Members';
                      this.$refs['filterPopup'].hide();
                    })
                    .catch(error => {
                      console.log("error filter info")
                      this.showLoader = false;
                    })
                  }*/

      },
    templateTypeList() {
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/getTemplateType',
          {}, {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {

        if (response.data.status == 'Success') {
          this.templateType = response.data.templateType;
          console.log(this.templateType);
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

      });
    },
    templateTypeChange(event) {
      axios.get(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/getEmailTemplateWithType/' + event.target.value,
          {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {

        if (response.data.status == 'Success') {
          this.emailTemplate = response.data.emailTemplate;
          console.log(this.emailTemplate);
        } else {
          console.log(response);
        }

      }).catch(error => {
        this.errors = error.response.data.errors || {};

      });
    },
    handleSubmit() {
      if(this.filterSubmit != true && this.form.status==1){
        this.errors.memberFilter = 'Please choose the assign member';
        this.sentLoader = false;
        return false;
      }
      let formData = new FormData();
      if (this.$refs.attachment.files.length != 0) {
        let attachment = this.$refs.attachment.files;
        for (let i = 0; i < attachment.length; i++) {
          if(attachment[i].size <= 2 * 1024 * 1024) {
            formData.append('files[' + i + ']', attachment[i]);
          }else{
            this.errors.attachment='File size exceeds maximum limit 2 MB.';
            this.sentLoader = false;
            this.$refs['confirmPopup'].hide();
            return;
          }
        }
      }
      // if (this.form.status == 1 && this.memberData.length == 0) {
      //   this.errors.memberFilter='Select Members';
      //   this.sentLoader = false;
      //   return;
      // }
      this.form.start_date = this.dateformat(this.form.date);
      console.log(this.form);
      if (Object.keys(this.form).length) {
        Object.entries(this.form).forEach(([key, value]) => {
          if(key=='date' && value==null){
            value=''
          }
          formData.append(key, value);
        });
      }
      console.log(formData);
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/campaign/store', formData
          , {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              "Content-Type": "application/json",
            }
          }).then(response => {
        if (response.data.status == 'Success') {
          this.searchForm.campaign_id = response.data.campaign.id;
          if((this.filterSubmit == true)) {
            this.assignMemberFilter();
          }
          // if (this.memberData.length != 0) {
          //   this.campaignListCreate();
          // }
          if (this.form.status == 1) {
            if(this.form.start_date == 'N/A' || this.form.start_date == 'Invalid date') {
              this.$refs['sentPopup'].show();
            } else {
              this.$refs['schedulePopup'].show();
            }
            setTimeout(() => {
              window.location.href = "/admin/campaign-history-reports";
            }, 1500);
          } else {
            this.$refs['confirmPopup'].hide();
            this.$refs['draftPopup'].show();
            setTimeout(() => {
            window.location.href = "/admin/campaign-history-reports";
          }, 1500);
          }
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.sentLoader = false;
        this.errors = error.response.data.errors || {};
        this.hideModal();
      });

    },
    hideModal() {
      this.$refs['confirmPopup'].hide();
    },
    emailSent() {
      this.sentLoader =true;
      this.form.status = 1;
      this.handleSubmit();
    },
    emailDraft() {
      this.form.status = 0;
      this.handleSubmit();
    },
    EmailTemplateChange(event) {
      axios.get(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/getEmailTemplate/' + event.target.value,
          {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {

        if (response.data.status == 'Success') {
          this.emailTemplateDetails = response.data.emailTemplate;
          this.form.subject = this.emailTemplateDetails.subject;
          this.form.message = this.emailTemplateDetails.message;
        } else {
          console.log(response);
        }

      }).catch(error => {
        this.errors = error.response.data.errors || {};

      });
    },
    campaignListCreate() {
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/campaignList/store/' + this.campaign_id, this.memberData
          , {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {
        if (response.data.status == 'Success') {
          console.log(response);
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};
        this.hideModal();
      });
    },
    getGroupList()
        {
            axios.get(this.basePath+'api/admin/group/list',{ headers:this.adminHeaders })
            .then((res) => {    
                this.groups = res.data.data;
            })
            .catch((error) => {
                console.log('Error:'+ error);               
            }).finally(() => {
            });
        },
    getRegions() {
      axios.get(this.basePath + 'api/regions')
          .then((res) => {
            this.regionsList = res.data.data;
          }).catch((error) => {
        console.log('Error:' + error);
        return false;
      })
    },
    getState() {
      this.listOfStates.forEach(code => {
        this.states.push({id: code['state_code'], text: code['state']});
      });
    },
    onChangeAttachment() {
        this.attachments = this.$refs.attachment.files;
    },
    clearFilter(){
          this.searchForm.contact = null;
          this.searchForm.status = '';
          this.searchForm.MembershipId= '';
          this.searchForm.year = '';
          this.searchForm.RegionName = '';
          this.searchForm.state_id ='';
          this.searchForm.byEmail = '';
          this.searchForm.group_id = '';  
    },
    isFutureDate(date) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1)
      return date < currentDate;
    },
    validation(contact){

      if(contact == 'All'){
          this.error = '';
          return false;
      }
      else if(this.searchForm.byEmail == undefined && this.searchForm.state_id == undefined && this.searchForm.RegionName  == undefined && this.searchForm.year == undefined &&  this.searchForm.MembershipId == undefined && this.searchForm.status == undefined){
         this.error = 'Please select atleat one field';
         return true;
      }
      else{ this.error = '';
        return false;
      }
    },
    resetAlert(){
      this.displayAlert = false;
      this.removeDangerBg();
    },
    dateformat(value){
      return (value == null)?'N/A':moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    assignMemberFilter(){
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/assignMemberFilter/store", this.searchForm, {
        headers: {
          "application-id": process.env.VUE_APP_APPLICATION_ID,
          'Content-Type': 'application/json'
        }
      }).then(response => {
        this.memberData = response.data.data;
      })
          .catch(error => {
            console.log("error filter info")
            this.showLoader = false;
          });
    },
    buttonCancel(){
      this.form.template_type_id = '';
      this.form.email_template_id = '';
      this.form.subject = '';
      this.form.message = '';
      this.form.content = '';
      this.form.start_date = '';
      this.form.attachment = [];
      this.form.date = '';
      this.form.from = '';
      this.attachments = [];
      this.clearFilter();
      this.button.filter = 'Assign Members';
    }

  },
    beforeMount() {
      this.templateTypeList();
      this.getMembership();
      this.getMembershipStatus();
      this.getSeason();
      this.getRegions();
      this.getState();
      this.getGroupList();
    },
  };
</script>
<style>
.modal.left .modal-dialog {
  right: 0;
  transition: opacity .3s linear, left .3s ease-out;
}

.modal.left .modal-dialog {
  position: fixed;
  margin: auto;
  width: 520px;
  height: 100%;
  transform: translateZ(0);
  top: -1px;
}

.rightsidepopup .modal-content {
  border-radius: 0px;
  height: 100vh;
}
</style>